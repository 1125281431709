import React from "react"

import { PhotoPlaceholder } from "react-placeholder-image"
import { GiStoneThrone } from "react-icons/gi"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const TamashaPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="grid-2-2 has-mb-5">
      <div
        data-sal="slide-up"
        data-sal-delay="500"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh", justifyContent: "space-between" }}
        className="has-padding-4  has-bg-blue align-content-vcenter has-txt-white"
      >
        <div>
          <h1 className="has-txt-bold" style={{ marginBottom: "1rem" }}>
            Tamasha
          </h1>
          <h3>
            <span className="has-mb-5 tags has-txt-bold">Logo design</span>{" "}
            <span className="has-mb-5 tags has-txt-bold">
              Stationary design
            </span>{" "}
          </h3>
        </div>
        <p></p>
      </div>

      <div
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh" }}
      >
        <img src={"/images/tamasha/1.jpg"} alt="" />
      </div>
      <div
        data-sal="slide-up"
        data-sal-delay="400"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh" }}
      >
        <img src={"/images/tamasha/2.jpg"} alt="" />
      </div>
      <div
        data-sal="slide-up"
        data-sal-delay="600"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh" }}
      >
        <img src={"/images/tamasha/3.jpg"} alt="" />
      </div>
    </div>

    <div className="grid-1-2 has-mb-5">
      <div
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh", cursor: "pointer" }}
      >
        <img src={"/images/tamasha/4.jpg"} alt="" />
      </div>
    </div>
  </Layout>
)

export default TamashaPage
